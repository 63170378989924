@import 'icons';
@import 'variables';
@import 'custom-bootstrap';
@import '~material-icons/iconfont/material-icons';

.zoom-on-hover {
    transition: transform .1s;   
}

.zoom-on-hover:hover {
    transform: scale(1.05);
}

.layout-container {
    @extend .container-fluid;
    @extend .pt-5;
    @extend .pb-4;
    @extend .p-sm-5;
}

.content-container {
    @extend .container;
    @extend .bg-white;
    @extend .rounded;
    @extend .shadow-sm;
    @extend .px-2;
    @extend .py-4;
    @extend .px-sm-3;
    @extend .py-sm-5;
    @extend .px-lg-5;
}

@include media-breakpoint-down(sm) {
    .small-xs {
        font-size: $small-font-size !important;
    }
}

.input-group-seamless {
    @extend .input-group;
}

.input-group-seamless .form-control:not(.is-invalid) {
    border-right: none;
}

.input-group-seamless .input-group-text {
    @extend .bg-white;
    @extend .py-0;
    @extend .text-primary;
    @extend .rounded-end;
    @extend .d-flex;
}

.h-0 {
    height: 0;
}

.collapsing.collapsing-no-transition {
    @extend .d-none;
    -webkit-transition: none;
    transition: none;
}

.rotate-180 {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}

.rounded-5 {
    border-radius: 1.25rem;
}