// custom bootstrap settings

// Bootstrap
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

.shadow-sm-top {
    box-shadow: 0 -0.25rem 0.25rem rgba(0, 0, 0, 0.075)
}

.shadow-sm-bottom {
    box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.075)
}

// redefine bootstrap colors
$theme-colors: (
  primary: #00285A,
  secondary: #647D9B,
  tertiary: #B4BECD,
  success: $success,
  info: $info,
  warning: $warning,
  danger: $danger,
  light: $light,
  dark: $dark,
  "white": $white,
);

$theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");
$utilities-colors: map-merge($utilities-colors, $theme-colors-rgb);
$utilities-text-colors: map-loop($utilities-colors, rgba-css-var, "$key", "text");
$utilities-bg-colors: map-loop($utilities-colors, rgba-css-var, "$key", "bg");

@import '~bootstrap/scss/bootstrap';