// define google material icon sizes
*[class*="material-icons"].md-18 {
    font-size: 18px;
}
*[class*="material-icons"].md-24 {
    font-size: 24px;
}
*[class*="material-icons"].md-28 {
    font-size: 28px;
}
*[class*="material-icons"].md-32 {
    font-size: 32px;
}
*[class*="material-icons"].md-36 {
    font-size: 36px;
}
*[class*="material-icons"].md-40 {
    font-size: 40px;
}
*[class*="material-icons"].md-48 {
    font-size: 48px;
}